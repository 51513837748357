import React, { useContext } from "react";
import { navigate } from "gatsby";
import {
  StyledIsBeekeeperModal,
  StyledIsBeekeeperModalTitleBlock,
  StyledIsBeekeeperModalBigText,
  StyledIsBeekeeperModalButton,
  StyledIsBeekeeperModalFormBlock,
} from "./style";
import { DataContext } from "../../contexts/DataContext";
import { LayoutContext } from "../../contexts/LayoutContext";

export default () => {
  const { labels, i18nLink } = useContext(DataContext);
  const { setModalContent } = useContext(LayoutContext);

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function setIsBeekeeper(isBeekeeper) {
    setCookie("IS_BEEKEEPER", isBeekeeper, 10 * 365);
    setModalContent(false);
  }

  return (
    <StyledIsBeekeeperModal>
      <StyledIsBeekeeperModalTitleBlock>
        <StyledIsBeekeeperModalBigText
          dangerouslySetInnerHTML={{ __html: labels.IS_BEEKEEPER_MODAL_TITLE }}
        />
      </StyledIsBeekeeperModalTitleBlock>

      <StyledIsBeekeeperModalFormBlock>
        <StyledIsBeekeeperModalButton type="button" id="yesbeekeeper" name="imbekeeper" onClick={() => {
          setIsBeekeeper(true);
          navigate(i18nLink("/tecnologia"));
        }}>
          {labels.YES}
        </StyledIsBeekeeperModalButton>
        <StyledIsBeekeeperModalButton type="button" id="nobeekeeper" name="nobekeeper" onClick={() => setIsBeekeeper(false)}>
          {labels.NO}
        </StyledIsBeekeeperModalButton>
      </StyledIsBeekeeperModalFormBlock>
    </StyledIsBeekeeperModal>
  );
};
